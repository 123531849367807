#contact-container {
    margin: 80px auto 0;
    overflow: hidden;
    background: #FAFBFC;
}

#contact-top {
    padding: 100px 8vw 0 8vw;
}

.contact-content-top {
    margin-bottom: 100px;
    label {
        font-size: 20px;
        // letter-spacing: 0.136905px;
        color: rgba(24, 24, 26, 0.3);
        margin-bottom: 20px;
    }
    h3 {
        font-weight: bold;
        font-size: 40px;
        line-height: 55px;
        /* or 132% */
        // letter-spacing: -1.06154px;
        color: #18181A;
        // max-width: 433px;
    }
    p {
        font-family: "AirbnbCerealApp-Medium";
        font-size: 20px;
        // line-height: 26px;
        /* or 162% */
        letter-spacing: auto;
        color: rgba(24, 24, 26, 0.7);
        margin-top: 20px;
        // max-width: 800px;
    }
    .button-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        
        button {
            background: #2689FF;
            border-radius: 5px;
            // font-weight: bold;
            font-size: 16px;
            line-height: 17px;
            /* identical to box height, or 106% */
            text-align: center;
            letter-spacing: 0.229048px;
            color: #FFFFFF;
            padding: 15px 30px;
            margin-right: 20px;
            margin-top: 20px;
            transition: all 0.2s ease-in;
            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 15px 15px 0 rgba(24, 24, 26, 0.08);
            }
            img {
                padding-right: 10px;
                margin-bottom: 5px;
            }

        }
    }
}

.contact-content-top-right {
    margin: 0 auto auto auto;
    position: relative;
    .top-img { 
        z-index: 9;       
        img {
            width: 90%;
            z-index: 2;        
        }
    }
    .bubble {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: -10%;
        z-index: -1;
    }
    .box {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -7%;
        margin-left: -5%;
        z-index: -1;
    }
    .map-info {
        margin-top: 10px;
        a {
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            color: #2689FF;
            text-decoration: none; 
            
            img {
                padding-left: 10px;
            }
        }
    }


}

#contact-form {
    padding: 50px 8vw 100px;
    p {
        padding-top: 20px;
        font-family: "AirbnbCerealApp-Book";
    }
    .form-control{
        margin-bottom: 20px;
    }
    .form-control::placeholder {
        font-family: "AirbnbCerealApp-Book";
    }
}

#contact-maps {
    padding: 0 8vw;
}

#contact-info {
    padding: 8vw 8vw;
    text-align: center;
    h2 {
        font-family: "AirbnbCerealApp-Bold";
        padding-bottom: 20px;
    }
}