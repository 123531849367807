#about-container {
    margin-top: 80px;
    padding-top: 60px;
    background-color: #FAFBFC;
    .content-top-header-teks {
        text-align: left;
        max-width: 874px;
        font-size: 40px;
        font-weight: bold;
        line-height: 55px;
    }
    .teks-deskripsi{
        margin: 0;
        max-width: 874px;
        padding-top: 20px;
        &.medium {
            max-width: 801px;
        }
    }
}

.about-content-top {
    padding: 0 8vw 8vw;
    background-image: url(../../img/about/bgabout.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-origin: padding-box;
    img {
        width: 100%;
    }
}

.about-content-top-teks {
    padding: 90px 0;
    label {
        font-size: 20px;
        color: rgba(24, 24, 26, 0.3);
        margin-bottom: 20px;
    }
}

#about-content-reward {
    padding: 8vw;
    text-align: center;
    h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
    }
    .img-container {
        max-width: 271px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin: 10px 5px;
        background-color: #fff;
        display: flex;
        align-items: center;
    }
    img {
        border-radius: 5px;
        width: 100%;
    }
}

#about-content-visimisi {
    padding-left: 8vw;
    .row {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 80px;
    }
    .about-visi {
        margin-left: 6vw;
        width: 100%;
        max-width: 702px;
        min-height: 391px;
        background: rgba(38, 137, 255, 0.05);
        padding: 8vw;
        z-index: 2;
        h3 {
            font-size: 40px;
            font-weight: bold;
            line-height: 55px;
        }
    }
    .about-misi {
        margin-left: 6vw;
        width: 100%;
        background: rgba(38, 137, 255, 0.05);
        padding: 8vw;
        z-index: 2;
        h3 {
            font-size: 40px;
            font-weight: bold;
            line-height: 55px;
        }
    }
}

.ornamen-bubble {
    position: absolute;
    z-index: 1;
    &.left {
        margin-top: 8vw;
        left: 6vw;
    }
    &.right {
        margin-top: -80px;
        right: 6vw;
    }
}

// RESPONSIVE

@media (min-width: 600px) and (max-width: 1000px) {
    // Product Responsive - START
    .content-product-menu {
        grid-template-columns: auto auto;
    }
    .content-product-menu-item {
        max-width: 100%;
    }
    // Product Responsive - END
}

@media (max-width: 768px) {
    #about-container {
        .content-top-header-teks {
            font-size: 24px;
            line-height: 30px;
        }
        .teks-deskripsi{
            font-size: 18px;
            padding-top: 5px;
        }
    }
    .about-content-top-teks {
        padding: 8vw;
    }

    #about-content-visimisi {
        padding-top: 50px;
        padding-left: 2vw;      
        .about-visi {
            min-height: 100%;
        }
    }

    .ornamen-bubble {
        opacity: 0.5;
        &.left {
            margin-top: 80px;
            left: 0;
        }
        &.right {
            right: 0;
        }
    }

    // Product Responsive - START
    
    #landing-page-product {
        h1 {
            font-size: 40px !important;
        }
        h2 {
            font-size: 30px !important;
        }
    }

    #product-content-top {
        padding-top: 15vw;
        padding-bottom: 0;
    }

    .product-content-rectangle-3 {
        width: 100%;
    }
    .content-product-menu-item {
        max-width: 100%;
    }

    // Product Responsive - END
}

@media (max-width: 425px) {
    .ornamen-bubble {
        &.left {
            transform: rotate(90deg);
            left: 10vw;
        }
        &.right {
            display: none;
        }
    }
}
