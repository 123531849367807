#landingpage-footer {
    background: #FAFBFC;
    padding: 100px 10vw 39px;

    h1 {
        font-weight: bold;
        font-size: 40px;
        line-height: 54px;
        letter-spacing: -0.530769px;
        padding: 10px 0 80px;
    }
}


.landingpage-footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0 60px;
}
    
.footer-content-teks {
    max-width: 308.3px;
    padding: 10px;

    h3 {
        font-weight: bold;
        font-size: 25px;
        line-height: 54px;
        letter-spacing: -0.331731px;
        padding-top: 30px;
    }

    p {
        color: rgba(24, 24, 26, 0.7);
        font-weight: 300;
        font-size: 15px;
        line-height: 25px;
    }
}

.footer-content-teks-menu {
    max-width: 308.3px;
    padding-top: 20px;
    h5 {
        font-weight: 800;
        padding-bottom: 20px;
    }
    p {
        margin-bottom: 10px;
        color: rgba(24, 24, 26, 0.7);
        max-width: 208.3px;
        font-family: 'Nunito';
        a {
            font-family: 'Nunito';
            text-decoration: none;
            color: rgba(24, 24, 26, 0.7);
            &:hover {
                border-bottom: 1px solid #18181a !important;
            }
        }
        img {
            margin-right: 10px;
        }
        // span {
        //     width: 120px;
        //     background: #FF5066;
        //     border-radius: 9.72px 9.72px 9.72px 0px;
        //     color: #fff;
        //     font-size: 10.53px;
        //     line-height: 27px;
        //     padding-left: 15px;
        //     margin-left: 20px;
        //     margin-top: -10px;
        //     height: 24px;
        //     position: absolute;
        //     animation: floating 3s infinite;
        // }
    }
}

.landingpage-footer-sosmed {
    text-align: center;
    a {
        padding: 0 5px;
    }
    p {
        img {
            margin-right: 7px;
        }
    }
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 2px); }
    to   { transform: translate(0, -0px); }    
}

@media (max-width: 768px) {
    .landingpage-footer-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 425px) {
    .landingpage-footer-content {
        grid-template-columns: repeat(1, 1fr);

        &.menu {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
