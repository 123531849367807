// utils
.icon-hero {
    position: absolute;
    width: 20% !important;
    max-width: 250px;
    z-index: 2;
    &.topright {
        margin-top: -25%;
        right: 10%;
        animation: float 3s infinite ease-in-out;
    }
    &.topleft {
        margin-top: -25%;
        left: 10%;
        animation-delay: -5s !important;
        animation: float 3s infinite ease-in-out;
    }
    &.bottomright {
        margin-top: 35%;
        right: 10%;
        animation-delay: -2s !important;
        animation: float 3s infinite ease-in-out;
    }
    &.bottomleft {
        margin-top: 35%;
        left: 10%;
        animation-delay: -1s !important;
        animation: float 3s infinite ease-in-out;
    }
}
.teks-deskripsi {
    line-height: 1.42;
    // color: rgba(24, 24, 26, 0.7);
    color: #7B818C;
    font-family: "Nunito";
    font-size: 20px;
    letter-spacing: auto;
    max-width: 800px;

    &.white {
        color: #ffffff;
    }
}
.btn-landing {
    padding: 15px 30px;
    // height: 61px;
    border-radius: 5px;
    border: none;
    text-transform: capitalize;
    transition: all 0.2s ease-in;
    box-shadow: 0px 15px 30px rgba(55, 84, 170, 0.29);
    font-family: 'Nunito';
    font-size: 16px;
    // font-weight: 700;
    font-weight: normal;

    &.blue {
        background-color: #2689ff;
    }

    &.dark-gray {
        background-color: rgba(0, 0, 39, 0.05);
        color: rgba(24, 24, 26, 0.5);

        &:active,
        &:focus {
            background-color: rgba(0, 0, 39, 0.2) !important;
        }
    }

    &.transparant {
        background-color: transparent;
        border: solid 1px #2689ff;
        color: #2689ff;
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 15px 15px 0 rgba(24, 24, 26, 0.08);
    }
    img {
        height: 30px;
        margin-right: 10px;
    }
}

.btn-witharrow {
    background-color: transparent;
    font-size: 16px;
    color: #2689ff;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 50px 0 0;
    font-family: "AirbnbCerealApp-Medium";

    span {
        font-size: 32px;
        position: absolute;
        margin-top: -38px;
        margin-left: 50px;
    }

    &:hover {
        background-color: transparent !important;
        color: #2688ffc0;
    }

    &:active,
    &:focus {
        background-color: transparent !important;
        color: #2688ffc0;
        box-shadow: none !important;
    }
}

.teks-bold {
    font-weight: 500;
}

.space-content {
    margin-top: 80px;
    margin-bottom: 120px;
}

// Container Content

#landingpage-container {
    width: 100%;
    background-color: #fafbfc;

    // content
    #landingpage-content {
        width: 100%;
        padding: 6vw 8vw 0;
        margin-top: 80px;
        text-align: center;
        position: relative;
    }
}

#content-solution {
    padding: 8vw;
    margin: 0;
    // background-color: #f3f4f5;
    background-image: linear-gradient(to top, #2689ff, #55b4d2);
    text-align: center;

    #ornament-1 {
        position: absolute;
        left: 0;
        margin-top: -220px;
    }
    #ornament-2 {
        position: absolute;
        right: 0;
    }
}

#content-client {
    padding: 0px 8vw;
    text-align: center;
}

// content wrepper -> used as wrapper for all content
.content-wrapper {
    padding-top: 40px;
    display: flex;
    align-items: center;
    width: 100%;

    &.flex-column {
        flex-direction: column;
    }

    &.image-large {
        
        justify-content: center;
        img {
            width: 100%;
        }
    }
}

.content-top-header-teks {
    font-weight: bold;
    line-height: 53px;
    letter-spacing: normal;
    color: #18181a;
    text-align: center;
    width: 100%;
    max-width: 100%;

    &.large {
        max-width: 1200px;
        font-size: 70px;
        margin: 0 auto;
        line-height: 80px
    }

    &.medium {
        font-size: 30px;
        max-width: 790px;
    }

    &.white {
        color: #ffffff;
    }

    span {
        color: #2689ff;
    }
}

// Content
#content-bottom,
#content-feature {
    margin-top: 50px;
}

.content-item {
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 7rem;

    &.bg-gray {
        background-color: #eeeeee;
        padding: 95px 8vw 10px;
    }

    &.row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.content-item-wrapper {
    h1 {
        font-size: 40px;
        line-height: 45px;
        font-weight: bold;
        padding-bottom: 43px;
        max-width: 351px;
        line-height: 1.33;
        letter-spacing: -0.53px;
    }
    h3 {
        font-weight: bold;
        font-size: 40px;
        line-height: 55px;
    }

    p {
        line-height: 35px;
        font-family: "Nunito";
        font-size: 20px;
        letter-spacing: auto;
        color:#7b818c;
        padding-top: 16px;
        padding-bottom: 15px;
        span {
            color: #2689ff;
        }
    }
    label {
        font-size: 20px;
        margin-bottom: 20px;
        font-family: 'Nunito';
        font-size: 12px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2689ff;
        text-transform: uppercase;
    }
}

.content-item-img {
    width: 100%;
    &.middle {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.content-solution {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 25px;
    justify-content: center;
}

.content-solution-item {
    max-width: 364px;
    width: 100%;
    min-height: 255px;
    border-radius: 5px;
    box-shadow: 0 15px 15px 0 rgba(24, 24, 26, 0.08);
    background-color: #ffffff;
    text-align: left;
    padding: 20px 50px 50px;
    margin-top: 40px;

    .solution-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
    }
    .solution-teks {
        p {
            font-size: 16px;
            color: #7b818c;
            font-family: 'Nunito';
        }
    }
    .solution-menu {
        font-weight: normal;
        color: #2689ff;
        padding-top: 20px;
        a {
            // font-family: "AirbnbCerealApp-Medium";
            font-family: "Nunito";
            font-weight: 700;
            text-decoration: none;
            margin-right: 20px;
            &:hover {
                border-bottom: 1px solid #2689ff !important;
            }
        }
        &.row {
            padding-top: 0;
            a {
                margin-right: 0;
            }
        }
    }
}

.content-klien-kami {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 850px;
}

@media (min-width: 1632px) {
    .content-klien-kami  {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .content-solution {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 767px) {
    .content-item {
        padding-bottom: 50px;
        flex-direction: column;

        &.bg-gray {
            padding-bottom: 100px;

            img {
                margin-top: 50px;
            }
        }
    }
    .row-reverse {
        flex-direction: column-reverse;
    }
    .content-solution {
        grid-template-columns: auto;
    }
    .content-solution-item {
        max-width: 100%;
        width: 100%;
    }
    .content-item-img {
        &.middle {
            width: 100%;
            padding: 10% 0 0 0;
        }
    }
}

@media (max-width: 600px) {
    .content-wrapper {
        .content-top-header-teks {
            line-height: 45px;

            &.large {
                font-size: 35px;
            }

            &.medium {
                font-size: 25px;
            }
        }
    }
    
    .icon-hero {
        width: 30% !important;
        &.topright,
        &.bottomright {
            right: 2%;
        }
        &.topleft,
        &.bottomleft {
            left: 2%;
        }
    }
}

@media (max-width: 425px) {
    .btn-landing {
        max-width: 200px;
        &.mb-3 {
            max-width: 100%;
        }
    }
    .content-wrapper {
        .content-top-header-teks {
            line-height: 37px;

            &.large {
                font-size: 30px;
            }

            &.medium {
                font-size: 20px;
            }
        }
        p {
            font-size: 18px;
        }
    }

    #content-solution {
        padding-top: 50px;
    }
}

.icon-speaker {
    border-radius: 100%;
    position: absolute;
    width: 5%;
    z-index: 2;
    &.left {
        top: 38%;
        left: 32%;
        animation: pulse1 1s infinite; 
    }
    &.right {
        top: 35%;
        right: 20%;
        animation: pulse2 1s infinite; 
    }
}

@keyframes float {
    from {
        transform: translate(0, 0px);
    }
    65% {
        transform: translate(0, 10px);
    }
    to {
        transform: translate(0, -0px);
    }
}

@keyframes pulse1 {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(140, 64, 175, 0.3);
      box-shadow: 0 0 0 0 rgba(140, 64, 175, 0.3);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(140, 64, 175, 0);
        box-shadow: 0 0 0 10px rgba(140, 64, 175, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(140, 64, 175, 0);
        box-shadow: 0 0 0 0 rgba(140, 64, 175, 0);
    }
}

@keyframes pulse2 {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(110, 196, 60, 0.3);
      box-shadow: 0 0 0 0 rgba(110, 196, 60, 0.3);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(110, 196, 60, 0);
        box-shadow: 0 0 0 10px rgba(110, 196, 60, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(110, 196, 60, 0);
        box-shadow: 0 0 0 0 rgba(110, 196, 60, 0);
    }
}
