@font-face {
    font-family: "AirbnbCerealApp-Book";
    font-style: normal;
    font-weight: normal;
    src: url("../../../fonts/AirbnbCerealBook.woff") format("woff");
}
@font-face {
    font-family: "AirbnbCerealApp-Light";
    font-style: normal;
    font-weight: normal;
    src: url("../../../fonts/AirbnbCerealLight.woff") format("woff");
}
@font-face {
    font-family: "AirbnbCerealApp-Medium";
    font-style: normal;
    font-weight: normal;
    src: url("../../../fonts/AirbnbCerealMedium.woff") format("woff");
}
@font-face {
    font-family: "AirbnbCerealApp-Bold";
    font-style: normal;
    font-weight: normal;
    src: url("../../../fonts/AirbnbCerealBold.woff") format("woff");
}

.modal-dialog {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
    height: 100%;
}

.modal-content {
    background-color: transparent;
    justify-content: center;
    padding: 0 !important;
    max-width: 760px;
    width: 100%;
    max-height: 515px !important;
    height: 100% !important;
    margin: auto;

    iframe {
        width: 100%;
        height: 100%;
    }
}


.btn-video-play {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: transparent;
    height: 67px;
    margin-top: 20px;
    margin-bottom: 50px;

    img {
        animation: pulse 1s infinite;  
        border-radius: 100%; 
    }

    p {
        color: #2689ff;  
        font-size: 24px;  
        margin: 0;
        font-weight: normal;
        font-family: 'AirbnbCerealApp-Book';
        padding-left: 20px;
    }

    &:hover {
        opacity: 0.8;
    }
    &:active,
    &:focus {
        outline: none !important;
    }
}

#notulite-homepage-v2 {
    background-color: #fafbfc;
    * {
        font-family: "AirbnbCerealApp-Medium";
    }
    .col {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.text-bold {
    font-family: "AirbnbCerealApp-Bold";
    font-size: 30px;
    line-height: 1.5;
    color: #18181a;
}
.btn-landing {
    &.blue-2 {
        background-color: #2689ff;
        
        &:active,
        &:focus {
            background-color: #1c6cce !important;
        }
    }
    &.transparant {
        background-color: transparent;
        border: solid 1px #2689ff;
        color: #2689ff;
    }
    &.white {
        color: #2689ff;
        background-color: #fff;
        &:active,
        &:focus {
            color: #247be6 !important;
            background-color: #fff !important;
        }
    }
    &.border-white {
        border: solid 2px #ffffff;
        background-color: transparent;
        
        &:active,
        &:focus {
            background-color: transparent !important;
        }
    }
    &:active,
    &:focus {
        box-shadow: none !important;
    }
}
// #landingpage-container {
//     width: 100%;
//     background-color: #fafbfc;
//     // content
//     #landingpage-content {
//         width: 100%;
//         padding: 5vw 8vw;
//         margin-top: 90px;
//         text-align: center;
//     }
// }

.content-image-wrapper {
    width: 50%;
    align-items: center;
    img {
        width: 100%;
    }
}
.content-item {    
    &.row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .content-item-wrapper {
        &.space-left {
            padding-left: 6vw;
        }
        // h3 {
        //     font-size: 30px;
        //     line-height: 45px;
        //     font-family: "AirbnbCerealApp-Bold";
        // }
        p {
            font-size: 20px;
            line-height: 35px;
            color: rgba(24, 24, 26, 0.7);
            padding-top: 16px;
        }
    }
    .content-item-img {
        width: 100%;
    }
}
#landingpage-content-top {
    padding-top: 50px;
    padding-bottom: 80px;
}
.content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.content-paragraf-small {
    margin-bottom: 30px;
    h4 {
        font-family: "AirbnbCerealApp-Bold";
        font-size: 20px;
        line-height: 30px;
    }
}
.content-paragraf {
    max-width: 483px;
    width: 50%;
    z-index: 2;
    h1 {
        font-family: "AirbnbCerealApp-Bold";
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -1.06154px;
    }
    p {
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 0.136905px;
        color: rgba(24, 24, 26, 0.7);
        padding: 10px 0;
    }
    &.right {
        text-align: right;
    }
    &.left {
        text-align: left;
    }
}
.content-top-header-teks {
    font-weight: bold;
    line-height: 53px;
    letter-spacing: normal;
    color: #18181a;
    text-align: center;
}
@media (min-width: 767px) {
    .content-additional {
        max-width: 48% !important;
    }
}
@media (max-width: 767px) {
    .content-item {
        flex-direction: column;
        margin-top: 50px;
        &.bg-gray {
            padding-bottom: 100px;
            img {
                margin-top: 50px;
            }
        }
    }
    .row-reverse {
        flex-direction: column-reverse;
    }
    .content-wrapper {
        flex-direction: column;
        &.left {
            flex-direction: column-reverse;
        }
    }
    .content-image-wrapper {
        width: 100%;
        margin-bottom: 50px;
        margin-top: 50px;
        &.small {
            width: 70%;
        }
    }
    
    .content-paragraf {
        width: 100%;
        &.right {
            text-align: left;
        }
    }
}
@media (max-width: 600px) {
    #landingpage-content-top {
        padding-bottom: 0;
    }
    .content-wrapper {
        .content-top-header-teks {
            line-height: 45px;
            &.large {
                font-size: 35px;
            }
            &.medium {
                font-size: 25px;
            }
        }
    }
    
    .content-additional {
        padding: 20vw 8vw;
    }
    .modal-content {
        max-height: 315px !important;
        height: 100% !important;
    }
}
@media (max-width: 425px) {
    .btn-landing {
        max-width: 200px;
    }
    .content-wrapper {
        .content-top-header-teks {
            line-height: 37px;
            &.large {
                font-size: 30px;
            }
            &.medium {
                font-size: 20px;
            }
        }
    }
}

.modal-open {
    .modal {
        padding-right: 0 !important;
    }
}


@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(38, 137, 255, 0.3);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(38, 137, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(38, 137, 255, 0);
    }
}

@keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(38, 137, 255, 0.3);
      box-shadow: 0 0 0 0 rgba(38, 137, 255, 0.3);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(38, 137, 255, 0);
        box-shadow: 0 0 0 10px rgba(38, 137, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(38, 137, 255, 0);
        box-shadow: 0 0 0 0 rgba(38, 137, 255, 0);
    }
}
