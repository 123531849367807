.card-container {
    &.small {
        width: 150px;
        min-height: 150px;
        border-radius: 5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
        margin: 10px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        img {
            width: 100%;
        }
    }
    p {
        max-width: 110px;
        font-family: "AirbnbCerealApp-Light";
        margin-bottom: 0;
        margin-top: 10px;
        line-height: 14px;
        font-size: 12.5px !important;
    }
}

@media (max-width:325px) {
    .card-container {
        &.small {
            width: 100px;
            min-height: 100px;
        }
        p {
            max-width: 90px;
        }
    }
}