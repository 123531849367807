/* Fonts */
@font-face {
  font-family: 'AirbnbCerealApp-Book';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCerealBook.woff') format('woff');
}
@font-face {
  font-family: 'AirbnbCerealApp-Light';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCerealLight.woff') format('woff');
}

@font-face {
  font-family: 'AirbnbCerealApp-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCerealMedium.woff') format('woff');
}

@font-face {
  font-family: 'AirbnbCerealApp-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCerealBold.woff') format('woff');
}

@font-face {
  font-family: 'AirbnbCerealApp-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCerealExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'AirbnbCerealApp-Black';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCerealBlack.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/roboto-v20-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'RobotoMedium';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/roboto-v20-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'AirbnbCerealApp';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/AirbnbCereal-Medium.ttf') format('ttf');
}

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/nunito-v14-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/nunito-v14-latin-700.woff') format('woff'), /* Modern Browsers */       
}
/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/nunito-v14-latin-800.woff') format('woff'), /* Modern Browsers */
}


/* inter-300 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/inter-v3-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v3-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v3-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v3-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v3-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v3-latin-300.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v3-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/inter-v3-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v3-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v3-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v3-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v3-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v3-latin-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/inter-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v3-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v3-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/inter-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/inter-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/inter-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/inter-v3-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/inter-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/inter-v3-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}


*, body {
  font-family: 'AirbnbCerealApp-Medium', sans-serif;
  /* line-height: 0; */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none;
}

section {
  /* min-width: 767px; // dilanding page bermasalah sama size ini._. */ 
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  border: none !important;
  outline: none !important;
}
button:hover {
  opacity: 0.9;
}