.testimony-container {
    display: flex;
    padding: 100px 8vw;

    .testimony-head-wrap {
        display: flex;
        flex-direction: column;
        margin: auto 30px auto 0;
        width: 450px;
        .head {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;
            /* or 132% */
            color: #18181A;
        }
        .sub-head {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 35px;
            /* or 175% */
            letter-spacing: 0.136905px;
            color: #7B818C;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .testimony-btn-wrap {
            display: flex;
            .icon-img-btn {
                margin-right: 20px;
            }
        }
    }
}

.testimony-wrapper {
    display: flex;
    width: 100%;    
    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 10px;
    } 
}
.testi-card-item {
    display: flex;
    flex-direction: column;
    background: rgba(44, 142, 250, 0.08);
    padding: 46.5px 49px 50px;
    max-width: 514px;
    min-width: 514px;
    margin: 24px;
    &.disabled {
        opacity: 0.5;
    }
    #quote-icon {
        height: 29px;
        width: auto;
        margin-bottom: 30px;
        margin-right: auto;
    }
    h4 {        
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height, or 130% */
        color: #18181A;
    }
    p {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        /* or 200% */
        letter-spacing: 0.136905px;
        color: #18181A;

    }
    .testi-id {
        display: flex;
        align-items: center;
        margin-top: 10px;
        #avatar {
            height: 51px;
            width: 51px;
            margin-right: 21px;
        }
        #id-name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 15px;
            line-height: 20px;
            /* or 200% */
            letter-spacing: 0.136905px;
            color: #18181A;
            margin: 0;
            padding: 0;
            span {
                display: block;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 30px;
                /* or 200% */

                letter-spacing: 0.136905px;

                color: #18181A;
            }
        }
    }
}

