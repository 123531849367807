#landing-page-product {
    margin-top: 90px;
    padding: 0 8vw 100px;
    background-color: #FFFFFF;
    color: #18181a;
    max-width: 100vw;
    width: 100%;
    :focus{
        outline: none;
    }
}

#product-content-top {
    padding-top: 110px;
    padding-bottom: 110px;
}

#product-content-top-a {
    // padding-top: 110px;
    padding-bottom: 80px;
}

.product-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product-content-image-wrapper {
    width: 40%;
    align-items: center;
    position: relative;

    &.small {
        width: 30%;
    }
    &.medium {
        margin-top: -80px;
        width: 40%;
        z-index: 5;
        max-height: 400px;
        margin-bottom: 50px;
    }
    &.right {
        margin-right: -8vw;
        margin-top: -150px;
        width: 50%;
    }
    
    img {
        width: 100%;
    }
}


.product-content-paragraf {
    max-width: 600px;
    width: 60%;
    margin-top: -40px;
    z-index: 2;

    &.medium {
        max-width: 546px;
    }

    h1 {
        font-size: 65px;
        // line-height: 1.26;
        letter-spacing: -1.06px;
        color: #202020;
        font-weight: bold;
    }
    .span-style {
        background-color: #FCCA17;
        width: 30px;
        height: 30px;
        display: inline-block;
        position: absolute;
        margin-top: 30px;
        z-index: -1;
        margin-left: -15px;
        border-radius: 100px;
    }
    h2 {
        font-size: 50px;
        font-weight: bold;
        line-height: 1.36;
        letter-spacing: -0.66px;
        color: #202020;
    }
    p {
        font-size: 23px;
        line-height: 1.57;
        letter-spacing: 0.14px;
        // color: #161637;
        color: rgba(24, 24, 26, 0.7);
        padding: 20px 0;

        &.s {
            font-size: 20px;
        }
    }
    button {
        padding: 15px 30px;
        border-radius: 7px;
        background-color: #086fe9;
        letter-spacing: 0.22px;
        text-align: center;
        color: #ffffff;
        outline: none;
        border: none;
    }

    &.right {
        text-align: right;
    }
}

.product-content-bubble {
    position: absolute;
    left: 0;
}

.product-content-rectangle {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    width: 45%;
    height: 580px;
    border-bottom-left-radius: 78px;
    background-color: #f8f8f8;
}

.product-content-rectangle-1 {
    position: absolute;
    width: 480px;
    height: 571px;
    border-radius: 9px;
    background-color: #f8f8f8;
    z-index: 0;
    top: 15;
    right: 0;
}

.product-content-rectangle-2 {
    position: absolute;
    width: 396px;
    height: 453px;
    border-radius: 9px;
    background-color: #f8f8f8;
    z-index: 0;
    top: 0;
}

.product-content-rectangle-3 {
    position: absolute;
    width: 630px;
    height: 518px;
    border-radius: 9px;
    background-color: #f8f8f8;
    z-index: 0;
    top: 40;
    left: 0;
    margin-top: 200px;
}

.product-content-rectangle-4 {
    position: absolute;
    width: 396px;
    height: 396px;
    border-radius: 9px;
    background-color: #f8f8f8;
    z-index: 0;
    top: 10;
    left: 10;
    margin-top: -130px;
    margin-left: 100px;
}

.landing-bottom-page-product {
    padding: 80px 8vw 0;
    background: rgb(249,249,249);
    background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(252,252,252,1) 85%, rgba(255,255,255,1) 100%);
}

#content-product-menu {
  
    h2 {
        margin: 0 auto;
        font-size: 30px;
        max-width: 978px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.14px;
        color: #18181a;
        text-align: center;
    }
    h5 {
        padding: 1.5rem 0;
        font-size: 20px;
        line-height: 1.75;
        letter-spacing: 0.12px;
        text-align: center;
        // color: #202020;
        color: rgba(24, 24, 26, 0.7);
        text-align: center;
    }
}

.content-product-menu {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-items: center;
    grid-gap: 20px;
    padding-top: 50px;
    padding-bottom: 167px;
}

.content-product-menu-item {
    max-width: 270px;    
    min-height: 309px;
    border-radius: 9px;
    // box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 32px 36px;
    transition: all 0.2s;

    h3 {
        font-size: 25px;  
        letter-spacing: 0.93px;
        color: #18181a;
        font-weight: bold;
        padding: 30px 0 12px;
        width: 80%;
    }
    p {
        font-size: 13px;  
        line-height: 1.54;
        letter-spacing: 0.46px;
        color: rgba(24, 24, 26, 0.7);
        font-family: 'AirbnbCerealApp-Book';
      
    }

    &:hover {
        background-color: #086fe9;
        // margin-top: -20px;
        transform: translateY(-20px);
        cursor: pointer;
        p,h3 {
            color: #fff ;
        }
    }
}

// Content
#content-bottom,
#content-feature {
    margin-top: 50px;
}

.content-item {
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 7rem;

    &.bg-gray {
        background-color: #eeeeee;
        padding: 95px 8vw 10px;
    }

    &.row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}


/* Responsive landing */

@media (max-width:1024px) {
    #landingpageproduct-content-partner {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }
}

@media (max-width:768px) {
    .product-content-wrapper  {
        flex-direction: column;
        &.left {
            flex-direction: column-reverse;
        }
    }
    .product-content-image-wrapper {
        width: 80%;
        margin-bottom: 50px;
        margin-top: 30px;

        &.small {
            width: 70%;
        }
        &.medium {
            width: 70%;
            margin-top: 20px;
            padding: 20px 0;
        }
        &.right {
            // margin-right: -8vw;
            margin-top: 80px;
            width: 100%;
        }
    }
    .product-content-paragraf {
        width: 100%;

        &.right {
            text-align: left;
        }
    }
    
    #landingpageproduct-content-feature {
        .content-feature-container {
            padding: 15vw 8vw;
        }
    }
    .landingpage-content-additional {
        padding: 50px 15vw;

        img {
            transform: translate(-15vw, 105px);
        }
    }
    
    #landingpageproduct-content-partner {
        grid-template-columns: auto auto auto;
    }

    .content-product-menu {
        grid-template-columns: auto auto;
    }

}

@media (max-width: 600px){
    
}

@media (max-width: 500px){
    
    .content-product-menu {
        grid-template-columns: auto;
    }

    #content-product-menu {
        h2 {
            font-size: 25px;
        }
    }
}