$space : 12vw;
$spacemobile : 8vw;

.backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    &.show {
        display: block;
    }
}

#navbar-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 8vw;
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    z-index: 10;  
    
    #responsive-navbar-nav {
        justify-content: flex-end;
        a {
            color: #18181a;
            // font-family: "AirbnbCerealApp-Book";
            font-family: "Nunito";
        }
    }

    .dropdown {
        // margin-right: 30px;
    }
    
    .dropdown-menu {
        position: fixed;
        z-index: 20;
        width: 100%;
        left: 0;
        height: 280px;
        top: 79px;
        border-radius: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: none;
        border-left: none;
        border-right: none;
        box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.1);
        animation: slide .5s ease;
    }
}

.btn-navbar-kontak-kami {
    font-weight: normal;
    font-size: 14px;
    padding: 10px 20px;
    font-family: 'Nunito';
    
    &.tentang-kami {
        font-size: 16px;
        margin-right: 15px;
    }

    &.transparant {
        background-color: transparent;  
        border: solid 1px #2689ff !important;
        color: #2689ff;
        margin-left: 15px;
    }
    &.blue {
        background-color: #2689ff;  
        // border: solid 1px #2689ff !important;
        color: #ffffff;
    }
    &:active, &:focus, &:hover{
        // background-color: transparent !important;
        opacity: 0.8;
        box-shadow: none !important;
        text-decoration: none;
        // color: #2689ff;
    }
}

.dropdown-menu-header {
    padding: 12px $space 50px;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 50px;
    margin-bottom: 60px;
}

.dropdwn-menu-item {
    max-width: 364px;
    width: 100%;
    border-radius: 5px;
    text-align: left;

    .solution-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 0;
        h4 {
            color: rgba(24,24,26,.15);
            font-size: 45px;
            font-family: 'AirbnbCerealApp-Medium';
            font-weight: bold;
        }
    }
    .solution-menu {
        font-weight: normal;
        color: #2689ff;
        padding-top: 10px;
        a {
            margin-right: 30px;
            text-decoration: none;
            &:hover {
                border-bottom: 1px solid #18181a !important;
            }
        }
    }
}

.dropdown-menu-footer {
    display: flex;
    align-items: center;
    background-color: rgba(24, 24, 26, 0.05);
    padding: 22px $space;
    position: absolute;
    bottom: 0;
    width: 100%;

    h4 {
        color: rgba(24, 24, 26, 0.7);
    }
    
    a {
        margin-left: 5vw;
        color: #2689ff !important;
        text-decoration: none;
        &:hover {
            border-bottom: 1px solid #2689ff !important;
        }
    }
}

@media (max-width: 1125px) {
    .dropdown-menu-header {
        padding: 12px $spacemobile 50px;
    }

    .dropdown-menu-footer {
        padding: 22px $spacemobile;
    }

    .dropdwn-menu-item {
        .solution-header {
            h4 {
                font-size: 30px;
            }
        }
    }
}

@media (max-width: 767px) {
    #navbar-main {
        .dropdown-menu {
            height: 400px;
        }
        
        .navbar-collapse {
            width: 100vw;
            background-color: #fff;
            position: fixed;
            left: 0;
            top: 80px;
            padding: 10px;
            .nav-item, button {
                margin-bottom: 10px;
                margin-left: 3vw;
            }
        }
    }
    .dropdown-menu-footer {
        flex-direction: column;
        align-items: flex-start;
        a {
            margin-left: 0px;
        }
    }
    .dropdown-menu-header {
        grid-template-columns: auto;
        grid-gap: 10px;
    }
    .dropdwn-menu-item {
        .solution-header,
        .solution-menu {
            padding: 2px 5px;
        }
    }
    .dropdwn-menu-item {
        .solution-header {
            h4 {
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 400px) {
    #navbar-main {
        .dropdown-menu {
            height: 450px;
        }
    }
}


@keyframes slide {
    from {
        margin-top: -20px;
    }
    to {
        margin-top: 0;
    }
}